/* Common imports */

import { styled } from "@mui/system";
import React from "react";
/* mui imports */
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Chip, useMediaQuery, useTheme } from "@mui/material";
import {
	agricultural_options_active,
	commercial_options_active,
	industrial_options_active,
	residential_options_active,
	warehousing_options_active,
} from "../../../../lib/data/search-filters/_e_property_type";
import StateInterface from "../../../../redux-magic/state-interface";
import {
	SearchFilterId,
	SearchFilterOption,
	SearchFilterOptionTextActive,
} from "../../../../redux-magic/sub-interfaces/search-types";
import {
	setSearchAnimationDetailsThunk,
	updateSearchFilterActiveOptionsThunk,
	updateSearchFilterAppliedThunk,
	updateSearchFilterSelectedOptionsThunk,
	updateSearchFilterVisibilityThunk,
} from "../../../../redux-magic/thunks";
/* redux imports */

/* stylings */
const PropertyChips = styled(Chip)(({ theme }) => ({
	display: "flex",
	alignSelf: "stretch",
	borderRadius: "8px",
	gap: "0.5rem",
})) as typeof Chip;

const PropertyCategory = ({
	search_id,
	search_filter_state,
	dispatch,
	searchanimationsettings,
	transaction_type,
}: {
	search_id: SearchFilterId;
	search_filter_state: StateInterface["search_filters_state"]["property_category"];
	dispatch: Function;
	searchanimationsettings: StateInterface["search_animation_settings"];
	transaction_type: string;
}) => {
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const handleClickPropertyChips = (value: Array<SearchFilterOptionTextActive>) => {
		let selected_options: Array<SearchFilterOption> = value.map((option) => {
			let { is_selected, ...rest } = option;
			return rest;
		});

		const selectedOptionId = value[0]?.id;

		const updatedActiveOptions = search_filter_state.active_options.map((item) => ({
			...item,
			is_selected: item.id === selectedOptionId,
		}));

		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: selected_options,
			}),
		);

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: search_id,
				newOptions: updatedActiveOptions,
			}),
		);

		const propertyTypeOptions =
			selectedOptionId === "residential"
				? residential_options_active
				: selectedOptionId === "commercial"
					? commercial_options_active
					: selectedOptionId === "agricultural"
						? agricultural_options_active
						: selectedOptionId === "warehousing"
							? warehousing_options_active
							: selectedOptionId === "industrial"
								? industrial_options_active
								: residential_options_active;

		if (selectedOptionId === "residential") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Bedroom", "Status", "Listed by", "Facing"],
					ResetSearch: false,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: false,
				}),
			);
		}
		if (selectedOptionId === "commercial" || selectedOptionId === "agricultural") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Status", "Listed by", "Facing"],
					ResetSearch: false,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: false,
				}),
			);
		}
		if (selectedOptionId === "warehousing" || selectedOptionId === "industrial") {
			dispatch(
				setSearchAnimationDetailsThunk({
					StartAnimate: false,
					TriggerSearch: false,
					PropertySubCategory: ["Budget", "Status", "Listed by"],
					ResetSearch: false,
					HomeAccordion: searchanimationsettings.HomeAccordion,
					OpenLocationDrawer: false,
				}),
			);
		}

		dispatch(
			updateSearchFilterActiveOptionsThunk({
				searchFilterId: "property_type",
				newOptions: propertyTypeOptions,
			}),
		);

		if (
			search_filter_state.selected_options.length === 0 ||
			search_filter_state.selected_options[0]?.id !== selectedOptionId
		) {
			dispatch(
				updateSearchFilterSelectedOptionsThunk({
					searchFilterId: "property_type",
					newOptions: [],
				}),
			);
		}
	};

	React.useEffect(() => {
		if (isDesktop) {
			const isApplied = search_filter_state.selected_options.length !== 0;
			const affectsId = search_filter_state.affects?.[0];

			if (affectsId) {
				dispatch(
					updateSearchFilterVisibilityThunk({
						searchFilterId: affectsId,
						isVisible: isApplied,
					}),
				);
			}

			dispatch(
				updateSearchFilterAppliedThunk({
					searchFilterId: search_id,
					isApplied: isApplied,
				}),
			);
			if (search_filter_state.selected_options[0]?.id === "warehousing") {
				dispatch(
					updateSearchFilterSelectedOptionsThunk({
						searchFilterId: "property_type",
						newOptions: [
							{
								id: "warehouse",
								title: "Warehouse",
								value: "Warehouse",
							},
						],
					}),
				);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search_filter_state, search_id, dispatch]);

	return (
		<div
			style={{
				display: "flex",
				flexFlow: "row wrap",
				alignItems: "center",
				gap: "1rem",
			}}
		>
			{search_filter_state.active_options?.map((item: SearchFilterOptionTextActive) => (
				<PropertyChips
					id={"home_desktop_filters_property_category_" + item.id}
					key={item?.id}
					onClick={() => {
						if (search_filter_state?.selected_options[0]?.id !== item.id) {
							handleClickPropertyChips([item] as Array<SearchFilterOptionTextActive>);
						}
					}}
					onDelete={() => {
						if (search_filter_state?.selected_options[0]?.id !== item.id) {
							handleClickPropertyChips([item] as Array<SearchFilterOptionTextActive>);
						}
					}}
					label={item?.title}
					clickable
					color="default"
					variant="outlined"
					deleteIcon={
						search_filter_state?.selected_options[0]?.id === item.id ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)
					}
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						fontSize: "1rem",
						backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801933" : "transparent",
						color: "black",
						border:
							search_filter_state?.selected_options[0]?.id === item.id ? "1px solid #FC8019" : "1px solid #C0C0C0",
						"&.MuiChip-clickable:hover": {
							backgroundColor: search_filter_state?.selected_options[0]?.id === item.id ? "#FC801929" : "transparent",
							boxShadow: "none",
						},
					}}
				></PropertyChips>
			))}
		</div>
	);
};
export default PropertyCategory;
